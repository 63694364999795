// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  apiBaseURL: 'http://139.59.28.84:8090/arinvoice/api',
  keycloak : {
    initConfig:  {
      url: 'http://139.59.28.84:18080/auth/',
      realm: 'globus-medical',
      clientId: 'AR-Client',
    }
  },
  projectPath: '/#/',
  userInfoPath: 'http://139.59.28.84:8090/erpmicroappcommon/api/loadUserInfo/',
  redisAccSearchEndPoint : 'http://139.59.28.84:8090/erpmicroappcommon/api/loadRedisDtls/repAccountRedis/',
  redisParentSearch : 'http://139.59.28.84:8090/erpmicroappcommon/api/loadRedisDtls/parentAccountRedis/',
  poMenuBaseURL : "https://erp.globusone.com/erpar/#/",
  accessEndPoint : "http://139.59.28.84:8090/erpmicroappcommon/api/getAccessPermissions/"
};
