import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {
  KeycloakAuthGuard,
  KeycloakService
} from 'keycloak-angular';
import { environment } from '../../environments/environment';
import * as SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
/**
 * This Class validates the user access by verifying/authenticating with keycloak.
 * It takes care of authentication and authorization
 */
export class AuthGuard extends KeycloakAuthGuard {
  public ls = new SecureLS({encodingType: 'aes', isCompression: true});
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  // Checks the token validity and allowed roles
  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      this.keycloak.logout();
      this.ls.removeAll();
      await this.keycloak.login({
        redirectUri: window.location.origin + environment.projectPath + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
