import { Component } from '@angular/core';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';
import * as SecureLS from 'secure-ls';
import { UserService } from '../../common-services/user-servic';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
/**
 * This file manages the default template items like, Top bar, Side bar and main body area.
 * This will be used by other views and the main body area will change based in selected page.
 */
export class DefaultLayoutComponent {
  public serviceResponse: any;
  public sidebarMinimized = false;
  public navItems = navItems;
  public displatName = '';
  public ls = new SecureLS({encodingType: 'aes', isCompression: true});
  public strUserName;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  constructor(
    private router: Router,
    private keycloak: KeycloakService,
    private userService: UserService
  ) {
  }
  ngOnInit(){
    this.strUserName = this.keycloak.getUsername();
    this.getUserDetails();
  }

  logout() {
    this.keycloak.logout();
    this.ls.removeAll();
    this.router.navigate(['/dashboard']);
  }

  getUserDetails(){
    if(this.ls.get('userDetails') == '') {
      this.userService.getUserDetails(this.strUserName).subscribe((data: any[])  => {
        this.serviceResponse = data;
        if(this.serviceResponse.length > 0){
          this.displatName = this.serviceResponse[0].strUserName;
          this.ls.set('userDetails', this.serviceResponse[0]);
          this.getUserPermission();
        }
      });
    } else {
      const userDetails = this.ls.get('userDetails');
      this.displatName = userDetails.strUserName;
    }
    return true;
  }

  getUserPermission(){
    if(this.ls.get('userPermission') == '') {
      let userId = this.ls.get('userDetails').strUserId;
      this.userService.getUserPermission(userId).subscribe((data: any[])  => {
        this.serviceResponse = data;
        if(this.serviceResponse.length > 0){
          let accessPermissionFlag = false;
          for (let index = 0; index < this.serviceResponse.length; index++) {
            const element = this.serviceResponse[index];
            if(accessPermissionFlag == false){
              if (element.updateAccessFl== "Y" && element.voidAccessFl== "Y"){
                accessPermissionFlag = true;
              }
            }
          }
          this.ls.set('userPermission', this.serviceResponse);
          this.ls.set('editAccess', accessPermissionFlag);
        }
      });
    }
  }
}
