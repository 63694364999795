import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';
import {
  LocationStrategy,
  HashLocationStrategy,
  CommonModule
} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {
  DatepickerModule,
  BsDatepickerModule
} from 'ngx-bootstrap/datepicker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DatePipe } from '@angular/common';
import {
  IconModule,
  IconSetModule,
  IconSetService
} from '@coreui/icons-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { CompanyService } from './common-services/company-service';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

// App Specific Imports
import { CustomDateHelper } from './helpers/custom-date-helper';
import { MasterDataService } from './common-services/master-data-service';
import {
  KeycloakAngularModule,
  KeycloakService
} from 'keycloak-angular';
import { NgxSpinnerModule } from "ngx-spinner";
import { initializeKeycloak } from './init/keycloak-init.factory';
import { AuthGuard } from './guard/auth.guard';
import { HttpErrorInterceptorModule } from './interceptor/http-error.module';
import { UserService } from './common-services/user-servic';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    AutocompleteLibModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    IconModule,
    IconSetModule.forRoot(),
    KeycloakAngularModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    HttpErrorInterceptorModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    DatePipe,
    IconSetService,
    CompanyService,
    CustomDateHelper,
    MasterDataService,
    UserService,
    AuthGuard
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
