/**
 * This file constains the enpoint details for all the service call.
 * Any future service calls endpoints should be updated here with proper hierarchy.
 * The endpoint details need to concatenated with the API base url from the environment file.
 */

export const Endpoints = {
    masters : {
        company : '/company/',
        companySettings : '/companyInfo/',
        ageing : '/ageing',
        paymentMode : '/paymentmode',
        invoiceType : '/invoicetype',
        fileStatus : '/fileStatus',
        finInstitution: '/finInstitution'
    },
    payments : {
        getPayments : '/invoicePayments',
        overrideInvoice : '/withInvoice/overrideInvoice',
        overrideAmount : '/withInvoice/overrideAmount',
        paymentComment : '/invoicePayment/comment',
        invoiceShortDetails : '/invoice/',
        mergeInvoice : '/invoices/merge',
        approval : '/invoicePayment/approval',
        addPayment : '/withoutInvoice/newPayment',
        updatePayment : '/withoutInvoice/update',
        deletePayment : '/withoutInvoice/delete/',
        mergedInvoice : '/wice/mergedInvoice'
    },
    reports : {
        detailedReport : '/detailReport',
        summaryReport : '/summaryReport',
        fileProcessReport : '/fileProcessorReport'
    },
    bankid : {
        getBankPartyAccountList : '/partyBankAccounts',
        updateBankIdMapping : '/bankIdDetail'
    },
    dashboard : {
        both : '/dashboard/',
        withInvoice : '/dashboard/withInvoice/',
        withoutInvoice : '/dashboard/withoutInvoice/'
    }
};
