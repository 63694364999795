/**
 * This file holds the messges to be shown for the user at different scenarios. i18n is not part of this project scope.
 * So, single language critical/generic messages are only maintained here.
 */
export const Messages = {
    service: {
        dataNF : 'No matching data found for the request. Please try other filters.',
        errorCode0 : 'Could not process your request at this moment. Please, try after sometime.', // This happens on timeout, CORS error,
        errorCode400 : 'Invalid request or session expired. Please refresh the page and login again.',
        errorCode401 : 'You don\'t have enough permission to perfom this. Please, contact system administrator.',
        errorCode404 : 'Requested information is not available or moved somewhere',
        errorCode500 : 'Server couldn\'t process your request at this time. Please, try after sometime.',
    },
    local: {
        serviceCallFail : 'Could not process your request at this moment. Please, try after sometime.'
    },
    userInfo: {
        refresh: 'Refreshing the details',
        allMandatoryField: 'Please, fill in the mandatory fields and try loading for data'
    },
    validations: {
        company: {
            selection: 'Please, choose the company.'
        },
        invoice : {
            duplicate: 'This invoice is already available in the grid. So, can\'t overide using this.'
        },
        overrideAmount: {
            validFormat: 'Override amount should be number. Alphabets and special characters are not allowed.'
        },
        paymentApproval :{
            noRecordsSelected: 'No records were selected. Please, select the payment and click approve.'
        }
    }
};
