import { INavData } from '@coreui/angular';
import { environment } from '../environments/environment';

export const navItems: INavData[] = [
  {
    name: 'Purchase Order',
    url: '/PurchaseOrder',
    children: [
      {
        name: 'Orders Pending PO',
        url: environment.poMenuBaseURL+'gmorderspendingpo',
        icon: 'icon-screen-desktop'
      },
      {
        name: 'Ready For Invoice Dashboard',
        url: environment.poMenuBaseURL+'gmautoinvoice',
        icon: 'icon-layers'
      },
      {
        name: 'Discrepancy PO',
        url: environment.poMenuBaseURL+'gmardiscrepancy',
        icon: 'icon-badge'
      },
      {
        name: 'Revenue Track Dashboard',
        url: environment.poMenuBaseURL+'gmrevenuedashboard',
        icon: 'icon-loop'
      },
      {
        name: 'Revenu Track Report',
        url: environment.poMenuBaseURL+'gmrevenuereport',
        icon: 'icon-chart'
      },
      {
        name: 'Discrepancy Detail Report',
        url: environment.poMenuBaseURL+'gmdiscdetailreport',
        icon: 'icon-paper-clip'
      }
    ]
  },
  {
    name: 'A/R Payments',
    url: '',
    children: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer'
      },
      {
        name: 'Payment With Invoice',
        url: '/payment/invoice',
        icon: 'icon-doc'
      },
      {
        name: 'Payment Without Invoice',
        url: '/payment/noinvoice',
        icon: 'icon-doc'
      },
      {
        name: 'Detailed Report',
        url: '/reportdetails/detailedreport',
        icon: 'icon-link'
      },
      {
        name: 'Report by Summary',
        url: '/reportdetails/reportbysummary',
        icon: 'icon-pie-chart'
      },
      {
        name: 'Invoice File Processing Report',
        url: '/reportdetails/fileprocessorreport',
        icon: 'icon-doc'
      },
      {
        name: 'Bank ID Setup',
        url: '/bank/bankidsetup',
        icon: 'icon-wrench'
      }
    ]
  }
];
