import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';

import { EMPTY, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Messages } from '../constants/Messages';
import { Injectable } from '@angular/core';

@Injectable()
/**
 * This class is of category http interceptor. It intercepts all the received service response and checks for error status.
 * If it's error response, it displays the respective message for the user. Else, pushes the success to the requesting service as it is.
 *
 * If we create any service in the future, this interceptor will automatically be implemented.
 */
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(public toastr: ToastrService) {}

    // Main interceptor function which parses the response.
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            retry(1), // if the request failes, it retries one more before deferring as error.
            catchError((error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
                this.clientSideError();
            } else {
                this.responseError(error.status);
            }
            return EMPTY; // Returns nothing to console
            })
        );
    }

    /**
     * This method is called when there is error from client side example internet failure and respective message is shown to the user.
     */
     clientSideError() {
        this.toastr.info(Messages.local.serviceCallFail);
    }

    /**
     * This method takes the error code as parameter and displays the matching messages.
     * @param errorCode | Number
     * @returns | None
     */
    responseError(errorCode: number) {
        let errorMessage = '';
        if (errorCode == 0) {
            errorMessage = Messages.service.errorCode0;
        }

        if (errorCode == 400) {
            errorMessage = Messages.service.errorCode400;
        }

        if (errorCode == 401) {
            errorMessage = Messages.service.errorCode401;
        }

        if (errorCode == 404) {
            errorMessage = Messages.service.errorCode404;
        }

        if (errorCode == 500) {
            errorMessage = Messages.service.errorCode500;
        }
        this.toastr.warning(errorMessage);
    }
}
