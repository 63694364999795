import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as SecureLS from 'secure-ls';

@Injectable()
/**
 * This helper class takes care of date related calulations and formatting.
 */
export class CustomDateHelper {
    public bsConfig: Partial<BsDatepickerConfig>;
    public ls = new SecureLS({encodingType: 'aes', isCompression: true});
    private dateFormat: string = 'MM/DD/YYYY'; // This can be replaced with Company Setting
    constructor(
        private datepipe: DatePipe
    ) {
        if (this.ls.get('companySettings')) {
            this.bsConfig = Object.assign({}, {
                showWeekNumbers:false,
                dateInputFormat: JSON.parse(this.ls.get('companySettings')).dtPickerFormat.toUpperCase(),
                customTodayClass:'custom-today-class'
            });
        } else {
            this.bsConfig = Object.assign({}, {
                showWeekNumbers:false,
                dateInputFormat: this.dateFormat,
                customTodayClass:'custom-today-class'
            });
        }
    }

    /**
     * This method gets the aging value and computes the date based on the pre-fixed rule and returns the from and to date in object.
     *
     * @param aging any
     * @returns dateRange
     */
    fromAndToDateByAgeing(aging: any) {
        const dateRange: any = {
            fromDate: '',
            toDate: ''        };

        if (aging == 'select') {
            dateRange.fromDate = '';
            dateRange.toDate = '';
        }
        if (aging == '110123') {
            dateRange.fromDate = this.getDateByDays(0);
            dateRange.toDate = this.getDateByDays(0);
        }
        if (aging == '110124') {
            dateRange.fromDate = this.getDateByDays(1);
            dateRange.toDate = this.getDateByDays(1);
        }
        if (aging == '110125') {
            dateRange.fromDate = this.getDateByDays(2);
            dateRange.toDate = this.getDateByDays(2);
        }
        if (aging == '110126') {
            dateRange.fromDate = this.monthStartDate(0);
            dateRange.toDate = this.getDateByDays(0);
        }

        return dateRange;
    }

    /**
     * This method takes the number of days to be subtracted from the current date and returns the computed date as dateobject.
     *
     * @param days | number
     * @returns requestedDate | DateObject
     */
    getDateByDays(days: number) {
        const today = new Date();
        const requestedDate = new Date(today);
        requestedDate.setDate(requestedDate.getDate() - days);
        return requestedDate;
    }

    /**
     * This method returns the day one of the given month.
     *
     * @returns monthStartDate | DateObject
     */
    monthStartDate(days:number) {
        const today = new Date();
        const requestedDate = new Date(today);
        requestedDate.setDate(requestedDate.getDate() - days);
        const monthStartDate = new Date(('0' + (requestedDate.getMonth() + 1)).slice(-2) + '-' + '01' + '-' + requestedDate.getFullYear());
        return monthStartDate;
    }
}

