import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Endpoints } from '../constants/Endpoints';

@Injectable()
/**
 * This is the common base service used to get any mater/reference details (In globus referred as Code Lookup)
 */
export class MasterDataService {

  public baseURL: any;

  constructor(
    private http: HttpClient
  ) {
      this.baseURL = environment.apiBaseURL;
  }

  getAgingList() {
    return this.http.get(this.baseURL + Endpoints.masters.ageing);
  }

  getPaymentModeList() {
    return this.http.get(this.baseURL + Endpoints.masters.paymentMode);
  }

  getInvoiceTypeList() {
    return this.http.get(this.baseURL + Endpoints.masters.invoiceType);
  }

  /**
   * This method is used to get the list of file status
   * @returns List of status
   */
  getStatusList() {
    return this.http.get(this.baseURL + Endpoints.masters.fileStatus);
  }


  getFinancialInstitution() {
    return this.http.get(this.baseURL + Endpoints.masters.finInstitution);
  }
}

