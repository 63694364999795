import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Endpoints } from '../constants/Endpoints';

@Injectable()
/**
 * This is the common base service used to get any information related to the user.
 */
export class UserService {

  public baseURL: string;

  constructor(
    private http: HttpClient
  ) {
      this.baseURL = environment.apiBaseURL;
  }

  /**
   * This method is used to get the details(name, userid, partyid) of the user
   * @param strUserName | string
   * @returns List of companies
   */
  getUserDetails(strUserName: string) {
    return this.http.get( environment.userInfoPath + strUserName);
  }

  /**
   * This method is used to get the user access permission(name, userid, partyid) of the user
   * @param strUserId | any
   * @returns List of Permissions
   */
   getUserPermission(strUserId: any) {
     console.log("Inside Service : "+strUserId);
    return this.http.get( environment.accessEndPoint + strUserId +"/ORD_PO_ENTRY_ACC");
  }

  /**
   * This method is used to the company specific setting like, date format, currency format, address etc.,
   * @param $companyID | Number
   * @returns Company configurations/settings Object
   */
  getCompanySettings($companyID: number) {
    return this.http.get(this.baseURL + Endpoints.masters.companySettings + $companyID);
  }

}

