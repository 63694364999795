import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Endpoints } from '../constants/Endpoints';

@Injectable()
/**
 * This is the common base service used to get any information related to the company.
 */
export class CompanyService {

  public baseURL: string;

  constructor(
    private http: HttpClient
  ) {
      this.baseURL = environment.apiBaseURL;
  }

  /**
   * This methods is used to get the associated companies for the logged in user.
   * @param strUserName | string
   * @returns List of companies
   */
  getCompanyList(strUserName: any) {
    return this.http.get(this.baseURL + Endpoints.masters.company + strUserName);
  }

  /**
   * This method is used to the company specific setting like, date format, currency format, address etc.,
   * @param $companyID | Number
   * @returns Company configurations/settings Object
   */
  getCompanySettings($companyID: number) {
    return this.http.get(this.baseURL + Endpoints.masters.companySettings + $companyID);
  }

}

