import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

/**
 * This function is called upon app initialization and validates the connection with keycloak server and registers the configurations.
 * @param keycloak | Instance of Keycloak service
 * @returns | Initialized Keycloak Object
 */
export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloak.initConfig,
      initOptions : {
        checkLoginIframe : false
      },
      loadUserProfileAtStartUp: true
    });
}
